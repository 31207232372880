import React from "react";
import NavbarMenu from "../components/Navbar";
import HeroContainer from "../components/HeroContainer/HeroContainer";

const MainLayout = () => {

    return (
        <div>
            {/* <NavbarMenu /> */}
            <HeroContainer />
        </div>
    )
}

export default MainLayout