import React, { useEffect, useState } from "react";
import logo from '../../assets/img/memojitransparent.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const HeroRight = () => {

    return (<>
        <div className="static-hero-right">
            <div className="static-hero-img">
                <div className="static-hero-img-inner">
                    <img src={logo} alt="" className="hero-avatar" />
                    <div className="icon-1 floating-item">
                        <FontAwesomeIcon className="text-warning" size="4x" icon={brands('js-square')} />
                    </div>
                    <div className="icon-2 floating-item">
                        <FontAwesomeIcon className="text-success" size="5x" icon={brands('node')} />
                    </div>
                    <div className="icon-3 floating-item">
                        <FontAwesomeIcon className="text-info" size="5x" icon={brands('react')} />
                    </div>
                    <div className="project floating-item">
                        <div className="p-text">
                            <h3>
                                <span >10000</span>+
                            </h3>
                            <p>Cups of coffee</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default HeroRight