import React, { useEffect, useState } from "react";
import HeroLeft from "./HeroLeft";
import HeroRight from "./HeroRight";

const HeroContainer = () => {

    return (<>
        <section className="static-hero">
            <HeroLeft />
            <HeroRight />
        </section>
    </>)
}

export default HeroContainer