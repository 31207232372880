import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, Form, NavLink } from "react-bootstrap";


const HeroLeft = () => {

    const PaypalDonateURL = "https://www.paypal.com/donate/?business=9F4XTFLTUXU2U&no_recurring=0&item_name=A+coffee+a+day+keeps+me+at+bay+%3D%5D&currency_code=USD"

    const BuymeCoffeePopup = () => {
        const w = 500;
        const h = 600;
        const t = '200';
        const l = '200';
        window.open(
            PaypalDonateURL,
            'popup',
            `scrollbars=yes,
            width=${w}, 
            height=${h},
            top=${t},
            top=${l}`
        ); return false;
    }

    return (<>
        <div className="hero-container">
            <div className="hero-inner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 col-12">
                            <div className="wpo-static-hero-inner">
                                <div data-swiper-parallax="300" className="slide-title">
                                    <h2>
                                        <span>Hello,</span>I am Rado. <span className="text-muted fs-5 pronounciation">(RAH-d-oh)</span>
                                    </h2>
                                </div>
                                <div data-swiper-parallax="300" className="slide-sub-title mt-5">
                                    <h5 className=" text-info ">I make stuff for the internets</h5>
                                </div>
                                <div data-swiper-parallax="400" className="slide-text">
                                    <p>
                                        In short, i'm a self taught tech entussiast with love of making stuff that ease your life come alive.
                                        <br />
                                        <br />
                                        Also, dogs are the bestest.
                                    </p>
                                </div>
                                <div className="clearfix"></div>
                                <div className="row">
                                    <div className="col-6">
                                        <Button className="bg-info rounded-pill btn-lg fs-5 py-3 px-5 border-0">
                                            Hire Me <FontAwesomeIcon icon={solid('briefcase')} />
                                        </Button>
                                    </div>
                                    <div className="col-6 text-end">
                                        <a
                                            href="#"
                                            onClick={BuymeCoffeePopup}
                                        >
                                            <Button className="bg-warning rounded-pill btn-lg fs-5 py-3 px-5 border-0 ms-auto">
                                                Buy me coffee <FontAwesomeIcon icon={solid('mug-hot')} />
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default HeroLeft